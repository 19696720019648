import { useEffect, useState } from "react";
import { postInquiry } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import { inputIsPhoneNumber, inputIsPhoneNumberWithCode } from "../../helpers";
import BtnSpinner from "../../Component/BtnSpinner";
import PopUp from "../../Component/PopUp";
import Consultation from "../../Assets/consultation.webp";
import Select from "react-select";


const ContactUsForm = ({ onSubmitted, subject = "", message = "", recaptcha = "" }) => {
  const [form, setForm] = useState({
    company_name: "",
    fullname: "",
    email: "",
    phonenumber: "+62",
    subject: subject,
    type: "",
    pic_position: "",
    message: message,
    eula_checkbox: false,
  });
  const [validation, setValidation] = useState({
    fullname: "",
  });
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const santizedForm = {
      ...form,
      message:
        "Hallo, perusahaan saya tertarik dengan layanan yang anda tawarkan. Bisa kah kami mendapatkan informasi lebih lanjut terkait " +
        form.subject.map((e) => e.label).join(", ") +
        " ?",
      subject: form.subject.map((e) => e.label).join(", "),
      pic_position: form.pic_position.value,
      recaptcha: recaptcha,
    };

    setSubmitting(true);
    const result = await postInquiry(santizedForm);
    if (result.success) {
      onSubmitted();
      setForm({
        company_name: "",
        fullname: "",
        email: "",
        phonenumber: "+62",
        position: "",
        subject: "",
        type: "",
        message: "",
        pic_position: "",
        eula_checkbox: false,
      });
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (
      form.company_name == form.fullname &&
      form.company_name != "" &&
      form.fullname != ""
    ) {
      setValidation({
        ...validation,
        fullname: "Company name and full name cannot be the same",
      });
    } else {
      setValidation({ ...validation, fullname: "" });
    }
  }, [form]);
  return (
    <>
      <ToastContainer />
      <div className="flex mt-10 md:mt-20 mb-[140px] mdb:flex-row flex-col">
        <div className="mdb:w-1/2 mdb:max-h-max max-h-[100px] w-full overflow-hidden">
          <img
            src={Consultation}
            className="object-cover h-full"
            alt="contact-us"
          />
        </div>
        <div className="mdb:w-1/2 w-full">
          <div className="bg-white drop-shadow-xl px-4 py-10 rounded-br-lg rounded-tr-lg">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col text-sm md:text-base text-left gap-3 z-0 max-w-[480px] mx-auto"
              method="post"
            >
              <div className="flex flex-col gap-2">
                <label htmlFor="last_name" className="text-sm">
                  Nama Lengkap
                </label>
                <input
                  type="text"
                  className="rounded-lg border-1 border-line-grey text-grey-600"
                  id="last_name"
                  placeholder="Nama lengkap anda"
                  name="last_name"
                  onChange={(e) =>
                    setForm({ ...form, fullname: e.target.value })
                  }
                  value={form.fullname}
                  required
                />
                {validation.fullname != "" && (
                  <small className="text-red-500 text-italic">
                    <i>{validation.fullname}</i>
                  </small>
                )}
              </div>
              <div className="flex flex-col gap-2 ">
                <label htmlFor="company_name" className="text-sm">
                  Nama Perusahaan
                </label>
                <input
                  type="text"
                  className="rounded-lg border-1 border-line-grey text-grey-600"
                  id="company_name"
                  placeholder="Nama perusahaan anda"
                  name="company_name"
                  onChange={(e) =>
                    setForm({ ...form, company_name: e.target.value })
                  }
                  value={form.company_name}
                  required
                />
              </div>

              <div className="flex flex-col gap-2">
                <label htmlFor="email" className="text-sm">
                  Email Perusahaan
                </label>
                <input
                  type="email"
                  className="rounded-lg border-1 border-line-grey text-grey-600"
                  id="email"
                  placeholder="Email perusahaan"
                  name="email"
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  value={form.email}
                  required
                />
              </div>
              {/* <div className="flex flex-col gap-2">
                <label htmlFor="phone_number" className="text-sm">
                  Phone Number
                </label>
                <input
                  type="tel"
                  className="rounded-lg border-1 border-gray-300 text-sm"
                  id="phone_number"
                  placeholder="Type your phone number.."
                  name="phone_number"
                  onChange={(e) => {
                    if (inputIsPhoneNumber(e.target.value)) {
                      setForm({ ...form, phonenumber: e.target.value });
                    }
                  }}
                  value={form.phonenumber}
                />
              </div> */}
              <div className="flex flex-col gap-2">
                <label htmlFor="pic_position" className="text-sm">
                  Jabatan/Posisi
                </label>
                <Select
                  className="py-0.5 rounded-lg border border-line-grey"
                  classNamePrefix={"select"}
                  options={[
                    { value: "Director/C-Level", label: "Director/C-Level" },
                    {
                      value: "Highr ID Resource - Talent Acquisition",
                      label: "Highr ID Resource - Talent Acquisition",
                    },
                    {
                      value: "Highr ID Resource - Organtization Development",
                      label: "Pengembangan Organisasi",
                    },
                    {
                      value: "Highr ID Resource - Others",
                      label: "Penentuan Promosi dan Rotasi Karyawan",
                    },
                    {
                      value: "Procurement/General Affair",
                      label: "Procurement/General Affair",
                    },
                    { value: "Job Seeker", label: "Job Seeker" },
                  ]}
                  value={form.pic_position}
                  onChange={(e) => {
                    setForm({ ...form, pic_position: e });
                  }}
                  placeholder={"Pilih Jabatan/Posisi anda "}
                  required
                />
                {/* <input
                  type="text"
                  className="rounded-lg border-1 border-line-grey text-grey-600"
                  id="pic_position"
                  placeholder="Jabatan atau posisi anda di perushaan"
                  name="position"
                  onChange={(e) =>
                    setForm({ ...form, pic_position: e.target.value })
                  }
                  value={form.pic_position}
                  required
                /> */}
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="subject" className="text-sm">
                  Kebutuhan perusahaan
                </label>
                <Select
                  className="py-0.5 rounded-lg border border-line-grey"
                  isMulti={true}
                  classNamePrefix={"select"}
                  options={[
                    {
                      value: "Seleksi dan Rekrutmen",
                      label: "Seleksi dan Rekrutmen",
                    },
                    {
                      value: "Pemetaan dan Profiling Karyawan",
                      label: "Pemetaan dan Profiling Karyawan",
                    },
                    {
                      value: "Pengembangan Organisasi",
                      label: "Pengembangan Organisasi",
                    },
                    {
                      value: "Penentuan Promosi dan Rotasi Karyawan",
                      label: "Penentuan Promosi dan Rotasi Karyawan",
                    },
                    {
                      value: "Analisa Kebutuhan Training",
                      label: "Analisa Kebutuhan Training",
                    },
                  ]}
                  value={form.subject}
                  onChange={(e) => {
                    setForm({ ...form, subject: e });
                  }}
                  placeholder={"Pilih Kebutuhan Perusahaan"}
                  required
                />
                {/* <select
                  className="rounded-lg border-1 border-gray-300 text-sm"
                  id="subject"
                  name="subject"
                  required
                  onChange={(e) =>
                    setForm({ ...form, subject: e.target.value })
                  }
                  value={form.subject}
                >
                  <option value="Berlangganan Paket">Berlangganan Paket</option>
                  <option value="Permintaan Demo Produk">Permintaan Demo Produk</option>
                  <option value="Dukungan Pelanggan">Dukungan Pelanggan</option>
                  <option value="Penawaran Khusus">Penawaran Khusus</option>
                  <option value="Kemitraan Bisnis">Kemitraan Bisnis</option>
                  <option value="Umpan Balik">Umpan Balik</option>
                </select> */}
              </div>
              {/* <div className="flex flex-col gap-2">
                <label htmlFor="message_body" className="text-sm">
                  Message
                </label>
                <textarea
                  id="message_body"
                  name="message_body"
                  rows="4"
                  className="rounded-lg border-1 border-gray-300"
                  placeholder="Type your message.."
                  onChange={(e) =>
                    setForm({ ...form, message: e.target.value })
                  }
                  value={form.message}
                  required
                ></textarea>
              </div> */}
              {/* <label htmlFor="eula_checkbox" className="text-sm">
                <input
                  type="checkbox"
                  id="eula_checkbox"
                  name="eula_checkbox"
                  required
                />
                <>&nbsp;</>I have read and agree to the Privacy Policy, and
                agree to be contacted by the company
              </label> */}
              
              <button
                className="mt-2 w-full bg-main-violate hover:bg-main-violate-900 px-5 py-3 text-sm md:text-base text-white font-semibold tracking-normal rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                type="submit"
                disabled={submitting || validation.fullname != ""}
              >
                {submitting ? <BtnSpinner /> : "Hubungi Kami"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsForm;
