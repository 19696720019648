import axios from "axios";
export const getArticles = async (
  language = "id",
  topic = null,
  tag = null,
  nextPage = null,
  limit = 10
) => {
  try {
    const response = await axios.get(
      `/api/web/articles?lang=${language}&limit=${limit}&page=${nextPage}${
        topic ? `&topic=${topic}` : ""
      }${tag ? `&tag=${tag}` : ""}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getArticle = async (slug = "") => {
  try {
    const response = await axios.get(`/api/web/articles/${slug}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getArticleTopics = async () => {
  try {
    const response = await axios.get(`/api/web/article-topics`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const likeArticle = async (slug = "") => {
  try {
    const response = await axios.post(
      `/api/web/articles/d/like`,
      {
        slug,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const unlikeArticle = async (slug = "") => {
  try {
    const response = await axios.delete(
      `/api/web/articles/d/like/${slug}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
