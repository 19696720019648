import BenefitPoint from "../../Component/BenefitPoint";
import HeroPage from "./HeroPage";
import Jumbotron from "./Jumbotron";
import Milestone from "./Milestone";
import Moment from "./Moment";
import OurMission from "./OurMission";

const CompanyPage = () => {
  return (
    <div className="flex flex-col gap-8 md:gap-[120px] mx-auto bg-white">
      <HeroPage />
      <Jumbotron />
      <OurMission />
      <Milestone />
      <Moment />
      <BenefitPoint />
    </div>
  );
};

export default CompanyPage;
