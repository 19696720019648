import { useTranslation } from 'react-i18next';
// import CompanyLogo1 from './../../Assets/Company Logo/Company_logo.svg';
// import CompanyLogo2 from './../../Assets/Company Logo/Company_logo1.svg';
// import CompanyLogo3 from './../../Assets/Company Logo/Company_logo2.svg';
// import CompanyLogo4 from './../../Assets/Company Logo/Company_logo3.svg';
// import CompanyLogo5 from './../../Assets/Company Logo/Company_logo4.svg';
// import CompanyLogo6 from './../../Assets/Company Logo/Company_logo5.svg';
// import CompanyLogo7 from './../../Assets/Company Logo/Company_logo6.svg';
// import CompanyLogo8 from './../../Assets/Company Logo/Company_logo7.svg';
// import CompanyLogo9 from './../../Assets/Company Logo/Company_logo8.svg';
// import CompanyLogo10 from './../../Assets/Company Logo/Company_logo9.svg';
import Milestone1 from "./../../Assets/Company Logo/img_pegadaian.webp";
import Milestone2 from "./../../Assets/Company Logo/img_jurusanku.webp";
import Milestone3 from "./../../Assets/Company Logo/img_kai.webp";
import Milestone4 from "./../../Assets/Company Logo/img_pbp.webp";
import Milestone5 from "./../../Assets/Company Logo/img_bri.webp";
import React from "react";

export default function Commitment(){
    const {t} = useTranslation();
    return(
        <div className="flex flex-col justify-center items-center mx-auto gap-11 px-7">
            <h2 className='font-bold text-main-violate'>{t('partner_title')}</h2>
            <div className="grid grid-cols-1 grid-rows-5 mdb:grid-cols-3 mdb:grid-rows-2 xl:grid-cols-5 xl:grid-rows-1 grid-flow-col overflow-x-auto gap-10 mx-auto">
                <img className='w-full xl:w-[150px] max-w-[150px]' src={Milestone1} alt='Pegadaian logo' />
                <img className='w-full xl:w-[150px] max-w-[150px]' src={Milestone2} alt='Jurusanku logo' />
                <img className='w-full xl:w-[150px] max-w-[150px]' src={Milestone3} alt='KAI logo' />
                <img className='w-full xl:w-[150px] max-w-[150px]' src={Milestone4} alt='PBP logo' />
                <img className='w-full xl:w-[150px] max-w-[150px]' src={Milestone5} alt='BRI logo' />
            </div>
        </div>
        
    );
}