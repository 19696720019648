import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeroPage from "./HeroPage";
import Percentage from "./Percentage";
import Commitment from "./Commitment";
import IntroVideo from "./IntroVideo";
import TitleBenefit from "./TitleBenefit";
import TitleSteps from "./TitleSteps";
import Testimony from "./Testimony";
import Footer from "../../Component/FooterLanding";
import ContentLanding from "../../Component/ContentLanding";
import HeroPage2 from "./HeroPage2";
import GettingStartedPage from "./GettingStartedPage";
import StartJourneyPage from "./StartJourneyPage";
import ReportPage from "./ReportPage";
import BenefitPoint from "../../Component/BenefitPoint";

const HomePage = ({}) => {
  return (
    <div>
      <div className="flex flex-col justify-center align-center gap-16 bg-white pb-[100px] ">
        <HeroPage />
      </div>
      <div className="flex flex-col gap-12 md:gap-28 justify-center align-center md:mx-auto bg-white pb-[120px]">
        <Percentage />
        <Commitment />

        {/* <TitleBenefit />
        <TitleSteps />
        <Testimony />
        <ContentLanding /> */}
      </div>
      <div className="flex flex-col justify-center align-center gap-16 bg-white pb-[100px] ">
        <HeroPage2 />
      </div>
      <div className="flex flex-col justify-center align-center gap-16 bg-white pb-[100px] ">
        <ReportPage />
      </div>
      <div className="flex flex-col justify-center align-center gap-16 bg-main-violate-50 pb-[100px] ">
        <GettingStartedPage />
      </div>
     
      <div className="flex flex-col justify-center align-center gap-16 bg-white py-[100px]">
        <IntroVideo />
      </div>
      <div className="flex flex-col justify-center align-center gap-16 bg-white landing-heropage3-container">
        <StartJourneyPage />
      </div>
    </div>
  );
};

export default HomePage;
