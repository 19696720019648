import HeroImage from "../HomePage/Components/HeroImage";
import {
  CursorArrowRippleIcon,
  ChatBubbleOvalLeftIcon,
} from "@heroicons/react/24/solid";
import Arrow from "./../../Assets/arrow_narrow_right.svg";
import React from "react";
import step1 from "./../../Assets/gs-step-1.webp";
import step2 from "./../../Assets/gs-step-2.webp";
import step3 from "./../../Assets/gs-step-3.webp";
import { useTranslation } from "react-i18next";
export default function GettingStartedPage() {
  const { t } = useTranslation();
  return (
    <div className="container text-center mx-auto w-full mt-10 flex flex-col px-7 mt-[100px] md:mt-[70px] gap-4">
      <h1 className="font-bold text-xl md:text-4xl mb-6">
        {t("gettingstarted_title")}{" "}
        <span className="text-main-violate"> 1-2-3!</span>
      </h1>
      <p className="mdb:text-lg text-md pt-1.5 max-w-xl text-center mx-auto mb-8">
        {t("gettingstarted_capt")}
      </p>
      <div className="container flex mdb:gap-[125px] gap-[40px] justify-center md:flex-row flex-col">
        <div className="flex justify-center">
          <img
            src={step1}
            className="overflow-hidden rounded-lg w-[220px] mdb:w-[280px]"
            alt="Admin Dashboard"
          />
        </div>
        <div className="flex justify-center">
          <img
            src={step2}
            className="overflow-hidden rounded-lg w-[220px] mdb:w-[280px]"
            alt="Admin Dashboard"
          />
        </div>
        <div className="flex justify-center">
          <img
            src={step3}
            className="overflow-hidden rounded-lg w-[220px] mdb:w-[280px]"
            alt="Admin Dashboard"
          />
        </div>
      </div>
    </div>
  );
}
