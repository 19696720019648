import React from "react";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
export default function Percentage() {
  const { t } = useTranslation();
  return (
    <div className="text-center mdb:mx-auto flex flex-col py-5 px-4 sm:px-0 bg-white flex-no-wrap mdb:flex-wrap mt-[80px] mb-[20px] w-[100%]">
      {/* <h1 className="font-bold text-lg mdb:text-4xl mb-8">
        Trusted By Various Companies <br />
        in various industrial sectors
      </h1>
      <div className="flex flex-wrap mdb:flex-nowrap lg:px-[150px] mt-8 w-full">
        <div className="w-1/2 mdb:w-full p-4">
          <h1 className="font-bold text-2xl ">150+</h1>
          <p>Company</p>
        </div>
        <div className="w-1/2 mdb:w-full p-4">
          <h1 className="font-bold text-2xl">100</h1>
          <p>Job Role</p>
        </div>
        <div className="w-1/2 mdb:w-full p-4">
          <h1 className="font-bold text-2xl">1000+</h1>
          <p>Job Applicant</p>
        </div>
        <div className="w-1/2 mdb:w-full p-4">
          <h1 className="font-bold text-2xl">2.600+</h1>
          <p>Assesment Completed</p>
        </div>
      </div> */}
      <h1 className="font-bold text-xl mdb:text-4xl mb-1">
        {/* {t('conventional').toUpperCase()} VS <span className="text-white bg-main-violate rounded-lg px-2">Highr ID</span> */}
        {t("humata_method")}
      </h1>
      <div className="flex mdb:flex-row flex-col items-center mt-8 w-full justify-between gap-[100px] mdb:gap-10">
        {/* <div className="old w-[100%] mdb:w-[50%] flex flex-col items-center">
          <p className="text-lg mdb:text-xl font-semibold mb-6">
            {t('conventional_capt')}
          </p>
          <p className="mb-16 max-w-[480px]">
            {t('conventional_desc')}
          </p>
          <div className="overflow-x-auto mdb:overflow-x-visible w-full flex flex-col sm:items-center">
            <div className="flex mt-6 gap-1">
              <div className="flex gap-1">
                <div>
                  <div className="bg-main-violate w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                    <p className="text-white font-semibold text-xs">
                      REGISTER CV
                    </p>
                  </div>
                  <small className="font-semibold">1 {t('days')}</small>
                </div>
                <ArrowLongRightIcon className="h-5 w-5 text-gray-500 mt-[15px]" />
              </div>
              <div className="flex gap-1">
                <div>
                  <div className="bg-main-violate w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                    <p className="text-white font-semibold text-xs">
                      PRE-INTERVIEW
                    </p>
                  </div>
                  <small className="font-semibold">1 {t('days')}</small>
                </div>
                <ArrowLongRightIcon className="h-5 w-5 text-gray-500 mt-[15px]" />
              </div>
              <div className="flex  gap-1">
                <div>
                  <div className="bg-main-violate w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                    <p className="text-white font-semibold text-xs">
                      TEST & ASSESSMENT
                    </p>
                  </div>
                  <small className="font-semibold">1 {t('days')}</small>
                </div>
                <ArrowLongRightIcon className="h-5 w-5 text-gray-500 mt-[15px]" />
              </div>
              <div className="flex gap-1">
                <div>
                  <div className="bg-main-violate w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                    <p className="text-white font-semibold text-xs">
                      FINAL INTERVIEW
                    </p>
                  </div>
                  <small className="font-semibold">1 {t('days')}</small>
                </div>
                <ArrowLongRightIcon className="h-5 w-5 text-gray-500 mt-[15px]" />
              </div>
              <div className="flex items-center gap-1">
                <div>
                  <div className="bg-main-violate w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                    <p className="text-white font-semibold text-xs">
                      WORKING AGREEMENT
                    </p>
                  </div>
                  <small className="font-semibold">1 {t('days')}</small>
                </div>
              </div>
            </div>
            <div className="flex mt-3 gap-1">
              <div className="flex items-center gap-1">
                <div className="bg-gray-500 w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                  <p className="text-white font-semibold text-xs">
                    APPLICATION <br />
                    FORM
                  </p>
                </div>
                <ArrowLongRightIcon className="h-5 w-5 text-gray-500" />
              </div>
              <div className="flex items-center gap-1">
                <div className="bg-gray-500 w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                  <p className="text-white font-semibold text-xs">
                    COORDINATE SCHEDULE
                  </p>
                </div>
                <ArrowLongRightIcon className="h-5 w-5 text-gray-500" />
              </div>
              <div className="flex items-center gap-1">
                <div className="bg-gray-500 w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                  <p className="text-white font-semibold text-xs">
                    TEST & ASSESSMENT
                  </p>
                </div>
                <ArrowLongRightIcon className="h-5 w-5 text-gray-500" />
              </div>
              <div className="flex items-center gap-1">
                <div className="bg-gray-500 w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                  <p className="text-white font-semibold text-xs">
                    FINAL INTERVIEW
                  </p>
                </div>
                <ArrowLongRightIcon className="h-5 w-5 text-gray-500" />
              </div>
              <div className="flex items-center gap-1">
                <div className="bg-gray-500 w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                  <p className="text-white font-semibold text-xs">
                    WORKING AGREEMENT
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="old w-[100%] mdb:w-[100%] flex flex-col items-center">
          {/* <p className="text-lg mdb:text-xl font-semibold mb-6">
            {t("humata_method")}
          </p> */}
          <p className="mb-16 max-w-[780px]">{t("humata_method_desc")}</p>
          <div className="overflow-x-auto w-full">
            <div className="flex mt-6 gap-1 justify-center">
              <div className="flex gap-1">
                <div>
                  <div className="bg-main-violate w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                    <p className="text-white font-semibold text-xs">
                      APP Highr ID
                    </p>
                  </div>
                  <small className="font-semibold">
                    {"<"} 1 {t("days")}
                  </small>
                </div>
                <ArrowLongRightIcon className="h-5 w-5 text-gray-500 mt-[15px]" />
              </div>
              <div className="flex gap-1">
                <div>
                  <div className="bg-main-violate w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                    <p className="text-white font-semibold text-xs">
                      FINAL INTERVIEW
                    </p>
                  </div>
                  <small className="font-semibold">
                    {"<"} 1 {t("days")}
                  </small>
                </div>
                <ArrowLongRightIcon className="h-5 w-5 text-gray-500 mt-[15px]" />
              </div>
              <div className="flex gap-1">
                <div>
                  <div className="bg-main-violate w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                    <p className="text-white font-semibold text-xs">
                      WORKING AGREEMENT
                    </p>
                  </div>
                  <small className="font-semibold">
                    {"<"} 1 {t("days")}
                  </small>
                </div>
              </div>
            </div>
            <div className="flex mt-3 gap-1 justify-center">
              <div className="flex items-center gap-1">
                <div className="bg-gray-500 w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                  <p className="text-white font-semibold text-xs">APP Highr ID</p>
                </div>
                <ArrowLongRightIcon className="h-5 w-5 text-gray-500" />
              </div>
              <div className="flex items-center gap-1">
                <div className="bg-gray-500 w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                  <p className="text-white font-semibold text-xs">
                    END-USER INTERVIEW
                  </p>
                </div>
                <ArrowLongRightIcon className="h-5 w-5 text-gray-500" />
              </div>
              <div className="flex items-center gap-1">
                <div className="bg-gray-500 w-[100px] h-[45px] rounded-3xl flex items-center justify-center">
                  <p className="text-white font-semibold text-xs">
                    ACCEPT OR <br />
                    REJECT
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
