import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/es/storage/session';
import storage from 'redux-persist/lib/storage';
import faqReducer from './features/faq/faqSlice';
import generalReduces from './features/general/generalSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['general'] // only assessment will be persisted
};

const rootReducer = combineReducers({
  faq: faqReducer,
  general: generalReduces,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export default store;