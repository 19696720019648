import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const RedirectPage = () => {
  let navigate = useNavigate();
  const [, setSigning] = useState(false);
  const { token } = useParams();
  // const checking = () => {
  //   setSigning(true);
  //   sessionStorage.removeItem("user");
  //   axios
  //     .get(`/api/invitation/${token}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       if (response.data.success) {
  //         localStorage.setItem("candidate_invitation", token)
  //         toast.success(response.data.message, {
  //           toastId: "login-success-toast",
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 1250
  //         });

  //         setTimeout(() => {
  //           navigate("/login", { replace: false });
  //         }, 2000)
  //       }
  //     })
  //     .catch((e) => {
  //       toast.error(e.response.data.message, {
  //         toastId: "login-error-toast",
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //       setTimeout(() => {
  //         navigate("/login", { replace: false });
  //       }, 2000)
  //     });
  // };

  useEffect(() => {
    // checking();
    window.location.replace(`https://app.highr.id/invitation/candidate/${token}`)
  }, [])
  // TEST 2

  return (
    <div>
      <div className="container mx-auto pt-12">
        <ToastContainer />
        <div className="mx-auto max-w-lg px-4 justify-center">
          <div className="bg-white rounded-lg shadow lg:w-[476px] lg:h-[485px] mb-[40px] ">
            <h1 className="mt-2 pt-[32px] font-bold text-black text-center text-[20px] tracking-[.01em] leading-[125%]">
              Invitation Checking
            </h1>
            {/* <p className="mt-3 text-gray-800 text-black text-center text-[14px] leading-[140%] tracking-normal">
              Hello candidate!
            </p> */}

            {/* <p className=" text-gray-800 text-black text-center text-[14px] leading-[140%] tracking-normal">
              Invitation Checking ...
            </p> */}
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedirectPage;
