import logo from "../Assets/HumataLogo2.png";
export default function Footer() {
  return (
    <div className="flex flex-col">
      <footer className="bg-gray-800  text-white px-7 py-8 lg:py-[87px] lg:px-[160px] font-thin">
        <div className="flex flex-col xl:flex-row">
          <div className="flex-1 flex-col w-full mb-10">
            <div className="flex text-white">
              <img
                src={logo}
                className="w-[90px] mb-2"
                alt="Flowbite Logo"
              />
              <p className="py-5">
                <h4 className="text-lg font-semibold "></h4>
              </p>
            </div>
            <div className="text-white w-[320px]">
              <p>
                Behavioral and skills assessment for your company&apos;s
                candidates.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-20">
            <div className="flex flex-row gap-6 md:gap-20">
              <div className="flex flex-col gap-6 w-full flex-wrap">
                <h4 className="text-lg font-semibold mb-4">Product</h4>
                <a href="#">Online Assessment</a>
                <a href="#">Big 5 Personalities</a>
                <a href="#">Cognitive Abilities</a>
                <a href="#">General Competencies</a>
                <a href="#">Artificial Intelligence</a>
              </div>
              <div className="flex flex-col gap-6 w-full">
                <h4 className="text-lg font-semibold mb-4">Company</h4>
                <a href="/company">About Us</a>
                <a href="/contact_us">Help Centre</a>
                <a href="/contact_us">Contact Us</a>
              </div>
            </div>
            <div className="flex flex-row gap-6 md:gap-20">
              <div className="flex flex-col gap-6 w-full">
                <h4 className="text-lg font-semibold mb-4">Resources</h4>
                <a href="/package">Packages</a>
                <a href="#">Compare Features</a>
                <a href="#">FAQ</a>
              </div>
              <div className="flex flex-col gap-6 w-full">
                <h4 className="text-lg font-semibold mb-4">Follow Us</h4>
                <a href="https://www.linkedin.com/company/humata-indonesia/" target="_blank" rel="noreferrer">Linkedin</a>
                <a href="https://www.instagram.com/humata.id/" target="_blank" rel="noreferrer">Instagram</a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full h-[1px] bg-gray-700 mt-10"></div>
        <div className="flex w-full justify-start md:justify-end mt-4 ">
          <p>©Highr ID 2023</p>
        </div>
      </footer>
    </div>
  );
}
