import { useState } from "react";
import PopUp from "../../Component/PopUp";
import SubmittedInquiryPopUp from "../../Component/SubmittedInquiryPopUp";
import ContactUsForm from "./ContactUsForm";
import { useTranslation } from "react-i18next";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import config from "../../config";

const ContactUs = () => {
  const { t } = useTranslation("translations");
  const [submitted, setSubmitted] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const [recaptcha, setRecaptcha] = useState("");
  const handleRecaptchaChange = (e) => {
    if (recaptcha != "") return;
    const value = e;
    setRecaptcha(value);
  };
  return (
    <>
      <div className="flex flex-col justify-center align-center gap-16 bg-white">
        <GoogleReCaptchaProvider reCaptchaKey={config.RECAPTCHA_SITE_KEY}>
          <GoogleReCaptcha onVerify={handleRecaptchaChange} />
        </GoogleReCaptchaProvider>
        <div className="container text-center mx-auto mb-11 flex flex-col justify-center align-center bg-white mt-[100px] md:mt-[70px] ">
          <div className="max-w-[840px] mx-auto">
            <h1 className="font-bold  text-xl md:text-3xl">
              {t("free_consultation_title")}
            </h1>
            {/* <p className="text-base pt-1.5 max-w-[480px] mx-auto">
              {t("contact_us_capt")}
            </p> */}
          </div>
          <div className="w-full  mx-auto mdb:px-0 px-4">
            <ContactUsForm
              onSubmitted={(e) => setSubmitted(true)}
              subject={query.get("subject")}
              message={query.get("message")}
              recaptcha={recaptcha}
            />
          </div>
        </div>
      </div>
      {submitted && (
        <PopUp>
          <SubmittedInquiryPopUp onClose={(e) => setSubmitted(false)} />
        </PopUp>
      )}
    </>
  );
};

export default ContactUs;
