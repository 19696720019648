import React, { Component, useEffect, useState } from "react";
import logo from "../Assets/HumataLogo.png";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { Link } from "react-router-dom";
import SidebarLanding from "./SidebarLanding";
import MenuIcon from "../Assets/menu.svg";
import CloseIcon from "../Assets/x.svg";
import { useSelector } from "react-redux";
import generalSlice, { setLanguage } from "../features/general/generalSlice";
import { useDispatch } from "react-redux";
import NavbarLink2 from "./NavbarLink2";
import { useTranslation } from "react-i18next";

export default function NavbarLanding() {
  const { t, i18n } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { language } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  useEffect(() => {
    // if(changed){
    //   window.location.reload();
    // }
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <div>
      <Navbar
        fluid={true}
        rounded={true}
        className="border-b mdb:border-none mdb:fixed w-full"
      >
        <div className="flex flex-1 justify-start align-center">
          <Navbar.Brand href="/" className="w-[90px]">
            <img src={logo} className="mr-3 p-2 m-22" alt="highr Logo" />
          </Navbar.Brand>
        </div>

        {/* Desktop Version */}
        {/* <Navbar.Toggle className="focus:ring-0 focus:ring-white hover:bg-white"/> */}
        <Navbar.Collapse className="hidden mdb:flex">
          <div
            className={`h-screen md:h-fit flex flex-col md:gap-8 md:flex-row hidden mdb:flex`}
          >
            <Navbar.Link href="/" activeClassName="active">
              Home
            </Navbar.Link>
            <Navbar.Link href="/solutions" activeClassName="active">
              Solutions
            </Navbar.Link>
            <Navbar.Link href="/package" activeClassName="active">
              Packages
            </Navbar.Link>
            <Navbar.Link href="/company" activeClassName="active">
              Company
            </Navbar.Link>
            <Navbar.Link href="/contact_us" activeClassName="active">
              Contact
            </Navbar.Link>
            <Navbar.Link href="/articles" activeClassName="active">
              Article
            </Navbar.Link>
          </div>
        </Navbar.Collapse>

        {/* Mobile Navbar */}
        <button className="p-4 mdb:hidden" onClick={toggleSidebar}>
          {isCollapsed ? (
            <img src={MenuIcon} alt="menu" />
          ) : (
            <img src={CloseIcon} alt="close" />
          )}
        </button>

        {/* Search bar */}
        <div className="flex-1 justify-center hidden mdb:flex mdb:justify-end align-center">
          <form className="max-w-sm px-4">
            {/* <div className="relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400 left-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              <input
                type="text"
                placeholder={t("search")}
                className="w-full py-2 pl-12 pr-4 text-gray-500 border rounded-md outline-none bg-gray-50 focus:bg-white focus:border-indigo-600 border-gray-300"
              />
            </div> */}
          </form>
          <span>
            <Dropdown
              className="bg-white-600"
              color={"gray"}
              label={language?.toUpperCase()}
            >
              <Dropdown.Item
                onClick={() => {
                  dispatch(setLanguage("id"));
                  window.location.reload();
                }}
              >
                Indonesia (ID)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  dispatch(setLanguage("en"));
                  window.location.reload();
                }}
              >
                English (EN)
              </Dropdown.Item>
            </Dropdown>
          </span>
        </div>
      </Navbar>

      <div
        className={`bg-white list-none mdb:hidden ${
          isCollapsed
            ? "transform translate-x-full transition-transform duration-300 ease-in-out"
            : `transform translate-x-0 transition-transform duration-300 ease-in-out h-screen`
        }`}
      >
        <div
          className={`flex flex-col justify-between h-[90%] ${
            isCollapsed ? "hidden" : ""
          }`}
        >
          <div>
            <NavbarLink2 label={"Home"} href={"/"} />
            <NavbarLink2 label={"Solutions"} href={"/solutions"} />
            <NavbarLink2 label={"Packages"} href={"/package"} />
            <NavbarLink2 label={"Company"} href={"/company"} />
            <NavbarLink2 label={"Contact"} href={"/contact_us"} />
            <NavbarLink2 label={"Article"} href={"/articles"} />
          </div>
          <div>
            {/* ELEMENT FOR CHANGE LANGUANGE */}
            <div className="flex py-4 space-x-2 justify-center">
              <a
                onClick={(e) => {
                  dispatch(setLanguage("id"));
                  
                  // window.location.reload();
                }}
                className={`text-md font-semibold ${
                  language == "id" ? "text-main-violate-600" : "text-gray-400"
                }`}
              >
                ID
              </a>
              <p> | </p>
              <a
                onClick={(e) => {
                  dispatch(setLanguage("en"));
                  // window.location.reload();
                }}
                className={`text-md font-semibold ${
                  language == "en" ? "text-main-violate-600" : "text-gray-400"
                }`}
              >
                EN
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
