import ReactDOM from "react-dom/client";
import "tw-elements";
import App from "./App";
import "./index.css";
import axios from "axios";
import "./i18n";
// STORE
import { Provider } from "react-redux";
import store from "./store";

const root = ReactDOM.hydrateRoot(document.getElementById("root"));

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authentication"] =
  "$2y$10$2K1ejfRRKhjWe.GjVBxw0uFaSIfh87rY7kdiV/sIMYsFxepiLD1v2";
axios.defaults.headers.common["Authorization"] =
  "user" in sessionStorage
    ? "Bearer " + JSON.parse(sessionStorage.getItem("user")).token
    : "";
axios.interceptors.response.use();
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.removeItem("user");
      window.location.href = "/login";
      //   const messages = error.response.data.message;
      //   const collapsed = Object.values(messages).reduce(
      //     (acc, curr) => acc.concat(curr),
      //     []
      //   );
    } else {
      return error.response;
    }
  }
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
