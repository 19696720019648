import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeroPage from "./HeroPage";
import PackagePlan from "./PackagePlan";
import Faqs from "./Faqs";
import CheckService from "./CheckService";

const PricingPage = ({}) => {
  let navigate = useNavigate();
  return (
    <div className="flex flex-col mx-auto bg-white">
      <HeroPage />
      <PackagePlan />
      <CheckService />
      {/* <Faqs/> */}
    </div>
  );
};

export default PricingPage;
