import { useEffect, useRef, useState } from "react";
import image1 from "./../../Assets/Moments/Card.svg";
import image2 from "./../../Assets/Moments/Card_1.svg";
import image3 from "./../../Assets/Moments/Card_2.svg";
import image4 from "./../../Assets/Moments/Card_3.svg";
import image5 from "./../../Assets/Moments/Card_4.svg";
import image6 from "./../../Assets/Moments/Card_5.svg";
import { useTranslation } from "react-i18next";

const Moment = () => {
  const { t } = useTranslation("translations");

  const Counter = ({ target, time = 10 }) => {
    const [count, setCount] = useState(0);
    const counterRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const onIntersection = (entries) => {
      const [entry] = entries;
      setIsVisible(entry.isIntersecting);
    };

    useEffect(() => {
      const observer = new IntersectionObserver(onIntersection, {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      });
      if (counterRef.current) {
        observer.observe(counterRef.current);
      }
      return () => observer.disconnect();
    }, []);

    useEffect(() => {
      if (isVisible) {
        const interval = setInterval(() => {
          if (count < target) {
            setCount(count + 1);
          } else {
            clearInterval(interval);
          }
        }, time); // adjust this value to change the speed of the counter

        return () => clearInterval(interval); // cleanup on unmount
      }
    }, [count, target, isVisible]);

    return <p ref={counterRef} className="text-[40px] md:text-[60px]">{`+${count}`}</p>;
  };

  return (
    <div className="mx-auto text-center mb-[100px]">
      <h1 className="font-bold text-2xl md:text-4xl">{t("total_applicant")}</h1>
      <div className="flex justify-center mt-2">
        <p className="max-w-[780px]">{t("total_applicant_capt")}</p>
      </div>
      <div className="flex gap-12 w-full justify-center mt-8 items-center">
        <div>
          <Counter target={173} time={10} />

          <p className="text-[15px] md:text-[20px]">
            Total <br />
            Applicants
          </p>
        </div>
        <div className="border-l-2 border-gray-700 h-[80px] mx-3"></div>
        <div>
          <Counter target={300} time={5} />

          <p className="text-[15px] md:text-[20px]">
            Completed <br />
            Assessment
          </p>
        </div>
      </div>
      {/* <h1 className="font-bold text-2xl md:text-4xl">{t("moment_title")}</h1> */}
      {/* <div className="overflow-x-auto flex flex-wrap px-7 md:px-[185px] mt-8 md:mt-16">
        <div className="w-1/3 p-4">
          <img src={image1} alt="Moments" />
        </div>
        <div className="w-1/3 p-4">
          <img src={image2} alt="Moments" />
        </div>
        <div className="w-1/3 p-4">
          <img src={image3} alt="Moments" />
        </div>
        <div className="w-1/3 p-4">
          <img src={image4} alt="Moments" />
        </div>
        <div className="w-1/3 p-4">
          <img src={image5} alt="Moments" />
        </div>
        <div className="w-1/3 p-4">
          <img src={image6} alt="Moments" />
        </div>
      </div> */}
    </div>
  );
};

export default Moment;
