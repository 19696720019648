import { useTranslation } from "react-i18next";
export default function IntroVideo() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col md:flex-row px-7 text-center md:text-left justify-center align-center gap-11 mx-auto">
      <div className="flex-1 my-auto">
        <h2 className="font-bold text-main-violate ">INTRODUCTION VIDEOS</h2>
        <h1 className="font-bold text-2xl md:text-4xl mb-2">
          {t("manage_candidate")} <br /> {t("assessment_optimally")}
        </h1>
        <p>
            {t("intro_video_capt")}
        </p>
      </div>
      <div className="flex-1 justify-end align-center mx-auto ">
        <iframe
          src="https://www.youtube.com/embed/qYQ506iKAlY?si=97zgaTEA1YbHxFa2"
          title="Highr ID"
          className="rounded-3xl w-full h-[317px] drop-shadow-xl"
          // style={{ flex:1 ,height: "100%" }}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </div>
    </div>
  );
}
