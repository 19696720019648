import Arrow from "../Assets/arrow_narrow_right.svg";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

export default function BenefitPoint() {
  const { t } = useTranslation("translations");
  return (
    <div className="mb-[140px] md:mb-[292px] mdb:mx-auto px-2">
      {/* <h1 className="font-bold text-2xl md:text-4xl text-center mb-8">
        {t("benefit_title")}
      </h1> */}
      <div className="rounded-3xl flex mdb:flex-row flex-col justify-start align-center gap-8 mdb:gap-[70px] py-8 mdb:px-16 px-6 max-w-[1440px] gradient-main-violate">
        <div className="mdb:w-[50%] w-full">
          <h2 className="font-bold text-base mdb:text-lg text-main-violate mb-4">
            {t("benefit_title")}
          </h2>
          <p className="font-bold mdb:text-2xl text-lg text-white ">
            {t("benefit_capt")}
          </p>
        </div>
        <div className="w-full mdb:w-[50%] flex flex-col gap-5">
          <div className="flex gap-4 items-start">
            <CheckCircleIcon className="text-yellow-300 mdb:w-[40px] w-[30px] flex-shrink-0" />
            <p className="text-white mdb:text-[18px]  text-[15px]">
              {t("benefit_point1")}
            </p>
          </div>
          <div className="flex gap-4 items-start ">
            <CheckCircleIcon className="text-yellow-300 mdb:w-[40px] w-[30px] flex-shrink-0" />
            <p className="text-white mdb:text-[18px] text-[15px]">
              {t("benefit_point2")}
            </p>
          </div>
          <div className="flex gap-4 items-start">
            <CheckCircleIcon className="text-yellow-300 mdb:w-[40px] w-[30px] flex-shrink-0" />
            <p className="text-white mdb:text-[18px] text-[15px]">
              {t("benefit_point3")}
            </p>
          </div>
          <div className="flex gap-4 items-start">
            <CheckCircleIcon className="text-yellow-300 mdb:w-[40px] w-[30px] flex-shrink-0" />
            <p className="text-white mdb:text-[18px]  text-[15px]">
              {t("benefit_point4")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
