import React from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { Dropdown } from "flowbite-react";

const DropdownDots = ({
  children,
  isPublish,
  label = <EllipsisVerticalIcon width={20} />,
}) => {
  return (
    <Dropdown
      arrowIcon={false}
      inline={true}
      className="bg-main-violate-50hover:bg-main-violate-600"
      label={label}
    >
      {children}
    </Dropdown>
  );
};

export default DropdownDots;
