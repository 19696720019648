import HeroImage from "../HomePage/Components/HeroImage";
import {
  CursorArrowRippleIcon,
  ChatBubbleOvalLeftIcon,
} from "@heroicons/react/24/solid";
import Arrow from "./../../Assets/arrow_narrow_right.svg";
import React from "react";
import CandidateDashboard from "./../../Assets/candidate-dashboard.webp";
import { useTranslation } from "react-i18next";
export default function HeroPage2() {
  const { t } = useTranslation();
  return (
    <div className="container text-center mx-auto w-full mt-10 flex flex-col px-7 mt-[100px] md:mt-[70px] gap-4">
      <h1 className="font-bold text-xl md:text-4xl">
        {t('heropage2_title')}{" "}
        <span className="text-main-violate">Dashboards</span>
      </h1>
      <p className="mdb:text-lg text-md pt-1.5 max-w-xl text-center mx-auto">
        {t('heropage2_caption')}
      </p>
      <div className="container flex justify-center align-center mt-8 rounded-lg  bg-orange-secondary-50 mdb:p-[50px] p-0">
        <img src={CandidateDashboard} className="w-full overflow-hidden rounded-lg box-shadow-1" alt="Admin Dashboard" />
      </div>
    </div>
  );
}
