import Arrow from "../Assets/arrow_narrow_right.svg";
import { useTranslation } from "react-i18next";

export default function ContentLanding() {
  const { t } = useTranslation("translations");
  return (
    <div className="bg-black rounded-3xl mb-[140px] md:mb-[292px] py-8 mdb:px-16 px-8 mdb:mx-auto">
      <div className="flex mdb:flex-row flex-col justify-start align-center gap-4">
        <div className="flex flex-col flex-3 gap-3">
          <h2 className="font-bold text-main-violate">{t('content_landing_feature')}</h2>
          <h1 className="font-bold mdb:text-4xl text-xl text-white max-w-[480px]  ">
            {t('content_landing_title')}
          </h1>
          <p className="text-white my-6">
            {t('content_landing_desc')}
          </p>
        </div>
        <div className="flex flex-1  align-center">
          <div className="flex my-auto">
            <a
              className="bg-main-violate hover:bg-main-violate-900 text-base px-4 py-3 text-base text-white font-semibold tracking-normal rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
              type="submit"
              href="/package"
            >
              <span className="flex items-center">
                {t('content_landing_button')}
                <img src={Arrow} alt="arrow" className="ml-2" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
