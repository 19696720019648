import FeatureCard from "./Components/FeatureCard";
import { ReactComponent as RightArrow } from "./../../Assets/arrow_narrow_right.svg";
import { ReactComponent as LeftArrow } from "./../../Assets/arrow_left.svg";
import Icon1 from "./../../Assets/briefcase.svg";
import Icon2 from "./../../Assets/user_add.svg";
import Icon3 from "./../../Assets/book_open.svg";
import Icon4 from "./../../Assets/calendar.svg";
import Icon5 from "./../../Assets/presentation_chart_bar.svg";
import Icon6 from "./../../Assets/cloud_download.svg";
import { useTranslation } from "react-i18next";

const Feature = () => {
  const { t } = useTranslation("translations");
  window.onload = function () {
    const buttonRight = document.getElementById("slideRight");
    const buttonLeft = document.getElementById("slideLeft");

    buttonRight.onclick = function () {
      document.getElementById("container").scrollLeft += 50;
    };
    buttonLeft.onclick = function () {
      document.getElementById("container").scrollLeft -= 50;
    };
  };
  return (
    <div className="flex flex-col">
      <h1 className="font-bold mt-10 md:mt-[126px] text-lg md:text-4xl text-center">
        {t("solution_feature_title1")} <br />
        {t("solution_feature_title2")}
      </h1>
      <p className="mt-5 text-center">{t("solution_feature_capt")}</p>

      <div className="flex flex-wrap mt-8 md:mt-16" id="container">
        {/* First Row */}
        <div className="w-full sm:w-1/2 mdb:w-1/3 p-4">
          <FeatureCard
            Icon={Icon1}
            Title={"Create Job"}
            Preview={t("feature1_capt")}
          />
        </div>
        <div className="w-full sm:w-1/2 mdb:w-1/3 p-4">
          <FeatureCard
            Icon={Icon2}
            Title={"Invite Candidate"}
            Preview={t("feature2_capt")}
          />
        </div>
        <div className="w-full sm:w-1/2 mdb:w-1/3 p-4">
          <FeatureCard
            Icon={Icon3}
            Title={"Questions Type"}
            Preview={t("feature3_capt")}
          />
        </div>

        {/* Second Row */}
        <div className="w-full sm:w-1/2 mdb:w-1/3 p-4">
          <FeatureCard
            Icon={Icon4}
            Title={"Deadline Date"}
            Preview={t("feature4_capt")}
          />
        </div>
        <div className="w-full sm:w-1/2 mdb:w-1/3 p-4">
          <FeatureCard
            Icon={Icon5}
            Title={"Report"}
            Preview={t("feature5_capt")}
          />
        </div>
        <div className="w-full sm:w-1/2 mdb:w-1/3 p-4">
          <FeatureCard
            Icon={Icon6}
            Title={"Screening Interview"}
            Preview={t("feature6_capt")}
          />
        </div>
      </div>

      {/* Button */}
      {/* <div className="flex md:hidden mt-5 justify-center gap-4 mt-6 ">
        <button
          className="bg-main-violate-100 text-base px-4 py-2 text-base text-white font-semibold tracking-normal h-[48px] rounded-full disabled:bg-violet-300 disabled:cursor-not-allowed"
          type="submit"
          id="slideLeft"
        >
          <LeftArrow />
        </button>
        <button
          className="bg-main-violate text-base px-4 py-2 text-base text-white font-semibold tracking-normal h-[48px] rounded-full disabled:bg-violet-300 disabled:cursor-not-allowed"
          type="submit"
          id="slideRight"
        >
          <RightArrow />
        </button>
      </div> */}
    </div>
  );
};

export default Feature;
