import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./LandingPages/HomePage";
import LandingLayout from './Layouts/LandingLayout';
import PricingPage from './LandingPages/PricingPage';
import ContactUs from './LandingPages/ContactUsPage';
import SolutionPage from './LandingPages/SolutionPage';
import CompanyPage from './LandingPages/CompanyPage';
import RedirectPage from './LandingPages/RedirectPage';
import ArticlePage from './Pages/Articles';
import DetailArticlePage from './Pages/Articles/DetailPage';

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				{/* ROUTE LANDING PAGES */}
				<Route path="/" element={<LandingLayout />}>
					<Route index element={<HomePage />} />
					<Route path="invitation/candidate/:token" element={<RedirectPage />} />
					<Route path="package" element={<PricingPage />} />
					<Route path="contact_us" element={<ContactUs />} />
					<Route path="solutions" element={<SolutionPage />} />
					<Route path="company" element={<CompanyPage />} />

					{/* ARTICLE */}
					<Route path="articles">
						<Route path="" element={<ArticlePage />} />
						<Route path=":slug" element={<DetailArticlePage />} />
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export default App;
