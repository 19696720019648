import JumbotronImage from "./../../Assets/astha_building_office.jpg";
import { useTranslation } from "react-i18next";
const Jumbotron = () => {
  const { t } = useTranslation("translations");
  return (
    <div className="flex flex-col-reverse md:flex-row justify-center align-center px-3 mdb:px-[153px] gap-11 mx-auto mb-4 mdb:mb-0">
      <div className="flex-1 justify-end align-center mx-auto h-[380px]">
        <img
          src={JumbotronImage}
          alt="Jumbotron"
          className="rounded-xl w-full h-full object-cover"
        />
      </div>
      <div className="flex-1 my-auto">
        <h2 className="font-bold text-main-violate ">{t('about_us').toUpperCase()}</h2>
        <h1 className="font-bold text-xl mdb:text-4xl">
          {t("about_us_title")}
        </h1>
        <p>
          {t("about_us_capt")}
        </p>
      </div>
    </div>
  );
};

export default Jumbotron;
