import { ReactComponent as Arrow } from "./../../../Assets/arrow_narrow_right.svg";

const FeatureCard = ({ Icon, Title, Preview }) => {
  return (
    <div className="flex flex-col gap-6 bg-gray-50 rounded-md p-6 md:p-12 h-full w-full">
      <div className="text-left justify-center align-center">
        <img src={Icon} alt="Icon" />
        <h1 className="font-bold text-xl md:text-2xl">{Title}</h1>
        <p className="text-sm md:text-base h-full">{Preview}</p>
      </div>
        <a href="#">
          {/* <span className="flex align-center text-main-violate">
            Read More
            <Arrow fill="#8F03ED" stroke="#8F03ED" />
          </span> */}
        </a>
    </div>
  );
};
export default FeatureCard;
