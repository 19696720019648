import axios from "axios";
export const postInquiry = async (form = null) => {
  try {
    const response = await axios.post(`/api/web/message-inquiry`, form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
