import HeroImage from "../HomePage/Components/HeroImage";
import {
  CursorArrowRippleIcon,
  ChatBubbleOvalLeftIcon,
} from "@heroicons/react/24/solid";
import Arrow from "./../../Assets/arrow_narrow_right.svg";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
export default function HeroPage() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    console.log("Language i18n", i18n.language);
  }, [i18n]);
  return (
    <>
      {i18n.language && (
        <div className="container text-center mx-auto w-full mt-10 flex mdb:flex-row flex-col mdb:flex px-7 mt-[70px] gap-8">
          <div className="mdb:w-[50%] w-[100%] flex items-center flex-col justify-center px-4">
            <h1 className="font-bold text-2xl md:text-5xl">{t("welcome")}</h1>
            <p className="mdb:text-lg text-md pt-1.5 max-w-xl text-center mx-auto">
              {t("welcome_capt")}
            </p>
            <div className="flex mt-5 justify-center space-x-4 w-full">
              {/* <a
                className="bg-main-violate hover:bg-main-violate-900 px-4 py-3 text-sm md:text-base text-white font-semibold tracking-normal rounded-lg flex flex-col items-center justify-center"
                type="submit"
                href="https://app.highr.id/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex space-x-2 items-center gap-2">
                  {t("btn_trial_account")}
                  <span>
                    <CursorArrowRippleIcon width={20} />
                  </span>
                </div>
              </a> */}
              <a
                className="bg-main-violate hover:bg-main-violate-900 px-4 py-3 text-sm md:text-base text-white font-semibold tracking-normal rounded-lg flex flex-col items-center justify-center"
                href="/contact_us?subject=Kami ingin mencoba Platform Highr ID"
              >
                <div className="flex space-x-2 items-center gap-2">
                  {t("free_consultation")}
                  <span>
                    <ChatBubbleOvalLeftIcon width={20} />
                  </span>
                </div>
              </a>
            </div>
          </div>
          <div className="mdb:w-[50%] w-[100%] px-0 mdb:px-4">
            <HeroImage />
            {/* <iframe
              src="https://www.youtube.com/embed/qYQ506iKAlY?si=97zgaTEA1YbHxFa2" 
              title="Highr ID" 
              className="rounded-3xl w-full h-[317px] drop-shadow-xl"
              // style={{ flex:1 ,height: "100%" }}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            /> */}
          </div>
        </div>
      )}
    </>
  );
}
