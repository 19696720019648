import AssesmentCard from "./Components/AssesmentCard";
import { useTranslation } from "react-i18next";
const PackagePlan = () => {
  const { t } = useTranslation("translations");
  return (
    <div className="mt-16 mb-16 md:mb-[100px]">
      <div className="bg-white flex justify-center overflow-x-auto px-4 py-4 w-full mb-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 w-full max-w-7xl justify-items-center">
          <AssesmentCard
            packageName="Starter"
            packageDesc={t("package_starter_capt")}
            // feature1={"Unlimited Access"}
            // feature2={"Max 1 role"}
            // feature3={"Unlimited Test"}
            // feature4={"1 Month"}
          />
          <AssesmentCard
            packageName="Pro"
            packageDesc={t("package_pro_capt")}
            // feature1={"Max 10 roles/month"}
            // feature2={"Max 7 test"}
            // feature3={"Plus"}
          />
          <AssesmentCard
            packageName="Enterprise"
            packageDesc={t("package_enterprise_capt")}
            // feature1={"Max 15 roles/month"}
            // feature2={"Max 7 test"}
            // feature3={"Premium"}
          />
          <AssesmentCard
            packageName="Enterprise +"
            packageDesc={t("package_enterprise_plus_capt")}
            // feature1={"Max 25 role/month"}
            // feature2={"Advance Test"}
            // feature3={"Humata Consultant"}
          />
        </div>
      </div>

      <div className="flex justify-center">
        <a
          className="bg-main-violate hover:bg-main-violate-900 text-base max-w-max px-6 py-4  text-base mdb:text-lg text-white font-semibold tracking-normal  rounded-xl disabled:bg-violet-300 disabled:cursor-not-allowed text-center"
          type="submit"
          href={`/contact_us?subject=Berlangganan Paket`}
        >
          Customize Your Package
        </a>
      </div>
    </div>
  );
};

export default PackagePlan;
