import { ReactComponent as GreenCheck } from "./../../../Assets/check_circle.svg";

const AssesmentCard = ({
  packageName,
  packageDesc,
  feature1,
  feature2,
  feature3,
  feature4,
}) => {
  return (
    <div className="flex w-[306px] flex-col gap-9 p-8 bg-white drop-shadow-lg flex-shrink-0">
      <div className=" flex gap-4 flex-col">
        <h1 className="font-bold text-xl">{packageName}</h1>
        <p>{packageDesc}</p>
      </div>
      <div className="flex flex-col gap-6  min-h-[50px]">
        <span className="flex gap-3 items-center ">
          {feature1 && (
            <>
              <GreenCheck />
              <p>{feature1}</p>
            </>
          )}
        </span>
        <span className="flex gap-3 items-center">
          {feature2 && (
            <>
              <GreenCheck />
              <p>{feature2}</p>
            </>
          )}
        </span>
        <span className="flex gap-3 items-center">
          {feature3 && (
            <>
              <GreenCheck />
              <p>{feature3}</p>
            </>
          )}
        </span>
        <span className="flex gap-3 items-center">
          {feature4 && (
            <>
              <GreenCheck />
              <p>{feature4}</p>
            </>
          )}
        </span>
      </div>
      {/* <a
        className="bg-main-violate hover:bg-main-violate-900 text-base w-full px-4 py-2 text-base text-white font-semibold tracking-normal  rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed text-center"
        type="submit"
        href={`/contact_us?subject=Interest in ${packageName} Package &message=Hello, I recently came across your services and I'm particularly interested in one of your packages. The features and benefits outlined caught my attention and I believe it could be a great fit for my needs. I would appreciate it if you could provide me with more detailed information about the package, including pricing and the process to get started. I'm also interested in knowing if there are any customization options available. Please feel free to contact me at your earliest convenience to discuss this further. I'm looking forward to exploring how your services can benefit me. Thank you.`}
      >
        Contact Our Sales
      </a> */}
    </div>
  );
};

export default AssesmentCard;
