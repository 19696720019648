import HeroImage from "../HomePage/Components/HeroImage";
import {
  CursorArrowRippleIcon,
  ChatBubbleOvalLeftIcon,
} from "@heroicons/react/24/solid";
import Arrow from "./../../Assets/arrow_narrow_right.svg";
import React from "react";
import { useTranslation } from "react-i18next";
export default function StartJourneyPage() {
  const { t } = useTranslation();
  return (
    <div className="container text-center mx-auto w-full flex flex-col px-7 gap-4 py-[120px]">
      <h1 className="font-bold text-white text-xl md:text-3xl mdb:text-5xl">
        {t('journey_big_title')} 
      </h1>
      <div className="flex mt-5 justify-center space-x-4 w-full">
        <a
          className="bg-main-violate hover:bg-main-violate-900 px-4 py-3 text-sm md:text-base text-white font-semibold tracking-normal rounded-lg flex flex-col items-center justify-center"
          type="submit"
          href="https://app.highr.id/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex space-x-2 items-center gap-2">
          {t('btn_trial_account')}
            <span>
              <CursorArrowRippleIcon width={20} />
            </span>
          </div>
        </a>
      </div>
    </div>
  );
}
