import Milestone1 from "./../../Assets/Company Logo/img_pegadaian.webp";
import Milestone2 from "./../../Assets/Company Logo/img_jurusanku.webp";
import Milestone3 from "./../../Assets/Company Logo/img_kai.webp";
import Milestone4 from "./../../Assets/Company Logo/img_pbp.webp";
import Milestone5 from "./../../Assets/Company Logo/img_bri.webp";
// import Milestone2 from "./../../Assets/Rectangle25401.svg";
import { useTranslation } from "react-i18next";
const Milestone = () => {
  const { t } = useTranslation("translations");
  return (
    <div className="mx-auto text-center">
      <h1 className="font-bold text-2xl md:text-4xl">{t("milestone_title")}</h1>
      <div className="flex justify-center mt-2">
        <p className="max-w-[780px]">{t("milestone_capt")}</p>
      </div>

      <div className="container mx-auto w-full h-full px-7 mt-12">
        <div className="relative wrap overflow-hidden h-full ">
          <div className="border-2-2 absolute border-opacity-20 border-gray-700 h-full border left-1/4 md:left-1/2"></div>
          <div className="mb-8 flex justify-center items-center w-full gap-4">
            <div className="order-1 w-1/12 md:w-5/12 font-bold text-right text-sm md:text-4x">
              {/* Sept, 2023 */}
            </div>
            <div className="z-20 order-1 bg-gray-300 shadow-xl w-3 h-3 rounded-full" />
            <div className="order-1 w-7/12 md:w-5/12 py-4 flex flex-col mdb:flex-row gap-6">
              <img src={Milestone1} alt="Milestone" className="w-[160px]" />
              {/* <p className="text-sm text-left ">{t("milestone1")}</p> */}
            </div>
          </div>
          <div className="mb-8 flex justify-center flex-row md:flex-row-reverse items-center w-full gap-4">
            <div className="order-1 w-1/12 md:w-5/12 font-bold text-right md:text-left text-sm md:text-4x">
            </div>
            <div className="z-20 order-1 bg-main-violate shadow-xl w-3 h-3 rounded-full" />
            <div className="order-1 w-7/12 md:w-5/12 py-4 flex flex-col mdb:flex-row-reverse gap-6">
              <img src={Milestone2} alt="Milestone" className="w-[160px]" />
              {/* <p className="text-sm text-left md:text-right ">
                {t("milestone2")}
              </p> */}
            </div>
          </div>
          <div className="mb-8 flex justify-center items-center w-full gap-4">
            <div className="order-1 w-1/12 md:w-5/12 font-bold text-right text-sm md:text-4x">
            </div>
            <div className="z-20 order-1 bg-gray-300 shadow-xl w-3 h-3 rounded-full" />
            <div className="order-1 w-7/12 md:w-5/12 py-4 flex flex-col mdb:flex-row gap-6">
              <img src={Milestone3} alt="Milestone" className="w-[160px]" />
              {/* <p className="text-sm text-left ">{t("milestone1")}</p> */}
            </div>
          </div>
          <div className="mb-8 flex justify-center flex-row md:flex-row-reverse items-center w-full gap-4">
            <div className="order-1 w-1/12 md:w-5/12 font-bold text-right md:text-left text-sm md:text-4x">
            </div>
            <div className="z-20 order-1 bg-main-violate shadow-xl w-3 h-3 rounded-full" />
            <div className="order-1 w-7/12 md:w-5/12 py-4 flex flex-col mdb:flex-row-reverse gap-6">
              <img src={Milestone4} alt="Milestone" className="w-[180px]" />
              {/* <p className="text-sm text-left md:text-right ">
                {t("milestone2")}
              </p> */}
            </div>
          </div>
          <div className="mb-8 flex justify-center items-center w-full gap-4">
            <div className="order-1 w-1/12 md:w-5/12 font-bold text-right text-sm md:text-4x">
            </div>
            <div className="z-20 order-1 bg-gray-300 shadow-xl w-3 h-3 rounded-full" />
            <div className="order-1 w-7/12 md:w-5/12 py-4 flex flex-col mdb:flex-row gap-6">
              <img src={Milestone5} alt="Milestone" className="w-[160px]" />
              {/* <p className="text-sm text-left ">{t("milestone1")}</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Milestone;
