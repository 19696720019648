import OurMissionImage from "./../../Assets/out_mission.webp";
import { useTranslation } from "react-i18next";
const OurMission = () => {
  const { t } = useTranslation("translations");
  return (
    <div className="flex flex-col md:flex-row text-left justify-center align-center px-3 mdb:px-[153px] gap-11 mx-auto">
      <div className="flex-1 my-auto">
        <h2 className="font-bold text-main-violate ">{t('our_mission').toUpperCase()}</h2>
        <h1 className="font-bold text-xl mdb:text-4xl">
        {t('our_mission_title')}
        </h1>
        <p>
        {t('our_mission_capt')}
        </p>
      </div>
      <div className="flex-1 justify-end align-center mx-auto h-[380px]">
        <img
          src={OurMissionImage}
          alt="Jumbotron"
          className="rounded-xl w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default OurMission;
