import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          search: "Search",
          benefit_title: "Feel the advantages of Highr ID.",
          benefit_capt:
            "Assessing with Science and Finding the Best with 300+ Assessment that have taken by Candidates with Accuracy and Automatication Reports",
          benefit_point1:
            "Accessible in every device, from mobile phone to desktop supported with Anti Cheating monitor and real time capture.",
          benefit_point2:
            "Run multiple test from technical to soft skills with easy adjustment for teams and companies for multiple candidates up to hundreds talents.",
          benefit_point3:
            "Adjusting assessment by categories and industry with personalized test for your candidates from operational to management level acquisition",
          benefit_point4:
            " Report on Candidates Assessment result real time and unlimited access to recruitment database with easy subscription plan for companies and Human Resources practitioner.",
          content_landing_feature: "CUSTOMIZE A PACKAGE NOW!",
          content_landing_title: "Start Recruiting & Assesing Your Candidates",
          content_landing_desc:
            "Are you ready to make your company more organized? Choose & Customize your package now!",
          content_landing_button: "Choose Package Now",
          btn_trial_account: "Get Trial Account",
          days: "Days",
          conventional: "Conventional",
          conventional_capt: "RECRUITERS NEED 5 DAYS OF WORK",
          conventional_desc:
            "Conducting recruitment with the old methods can take up to 5 days of sorting candidates, running user test, screening candidates and monitoring multiple database",
          humata_method: "SORT, TEST AND ACCEPT IN 1 DAYS",
          humata_method_desc:
            "Simplify your hiring with reliable assessment platform that also can help you register candidate easily, compiled candidates result with timely and accurate report that help your companies growth.",
          contact_us: "Contact Us",
          free_consultation: "Free Consultation",
          free_consultation_title:
            "Take the first step in recognizing and managing data-based talents",
          welcome: "Hire Best Candidate",
          welcome_capt:
            "We are a company that provides employee and skills appraisal services. We help your company find the right talent.",
          partner_title: "Client That Have Subscribed",
          heropage2_title: "Assess and Manage Candidates with",
          heropage2_caption:
            "Here at Highr ID we focus on recruiting and assessing candidates with to suit the needs of each job",
          reportpage_title: "Identify the best candidates with",
          reportpage_capt1: "Empower Your Hiring Process:",
          reportpage_capt2:
            "Identify the Best Candidates with Our Advanced Tools",
          gettingstarted_title: "Getting started is as easy as",
          gettingstarted_capt:
            "In just a few easy steps, you are ready to manage your candidate assessment.",
          journey_big_title:
            "Start Recruiting & Assessing Your Candidates with Highr ID Now",
          solution_title1: "Improve Your Candidate",
          solution_title2: "Selection Process",
          solution_capt:
            "Assessment of candidates with the highest level of validation to assess cognitive ability, competence, motivation and personality.",
          solution_feature_title1: "Trusted By Various Companies",
          solution_feature_title2: "in various industrial sectors",
          solution_feature_capt:
            "This feature can make it easier for you to assess and recruit candidates in your company.",
          feature1_capt:
            "The client has added a new job to the system, which aims to include the job role that will be assigned to the candidate into the assessment process.",
          feature2_capt:
            "Clients have the ability to send invitations to candidates with the intention of involving them in carrying out pre-selected tests in order to measure their abilities and qualifications.",
          feature3_capt:
            "The structure of the test provides a choice of multiple question types to measure candidates' skills and knowledge, giving clients the freedom to choose according to their evaluation.",
          feature4_capt:
            "The application of time restrictions in assessments aims to reduce cheating and increase the efficiency and accuracy of participant evaluations.",
          feature5_capt:
            "Download reports as proof of the candidate's test implementation and results, play an important role in further evaluation and monitoring of performance.",
          feature6_capt:
            "The client has the option to conduct screening interviews with candidates. The client can also select specific questions from the platform for candidates to answer and have the ability to view real-time recordings of their responses.",
          solution_ct_tab:
            "Running an assessment for candidates that can be adjusted with your companies needs and support recruitment practices by providing personalized recommendation by our consultant team for assessment based on your industry profile with shortlisted test that are aligned to job qualifications from entry-level positions to management level.",
          solution_cr_tab:
            "Monitoring candidates assessment progress with timely report that has been analyze with scientific statistics that are compiled into one source of information running on candidates strengths and weaknesses, skill and knowledge that help recruiters and companies oversee potential candidates and forecast future talent acquisition on one simplify database",
          solution_cs1_tab:
            "Providing services that are aligned with our product, our consultant team will help you improve and run your recruitment process with determination following the hiring objective and practices to bring clarity that could help your growth your companies.",
          solution_cs2_tab:
            "Our other services that we can assist during your acquisition process are from resourcing up to end-to  end interview with database modules by your request",
          solution_rate_candidate: "Candidate Test Assessment Rate",
          solution_rate_candidate_capt:
            'Able to produce various actions based on the evaluation process carried out on each candidate, which includes an assessment as "good fit", "may be", or "not fit". Based on the actions given, each candidate will receive a message that matches the results of the assessment, helping them understand the extent of their suitability for the intended position.',
          solution_display_match: "Display The Candidate’s Match Value",
          solution_display_match_capt:
            "Shows the percentage of fit between the candidate's profile and required job components, while providing a comprehensive and relevant summary of information about the candidate. It aims to support the company in making decisions.",
          package_title: "Highr ID Packages Plan",
          package_capt:
            "You can access each month and each assessment, according to your company’s needs",
          package_starter_capt:
            "This is the basic package, perfect for individuals or small businesses just starting out. It provides access to some cool features to begin the assessment process.",
          package_pro_capt:
            "This package is designed for professionals who need more advanced features. It allows for a greater number of roles and tests per month, making it suitable for growing businesses.",
          package_enterprise_capt:
            "Aimed at larger businesses or enterprises, this package offers even more roles and tests per month. It includes premium features to handle larger volumes of assessments.",
          package_enterprise_plus_capt:
            "This package is designed for professionals who need more advanced features. It allows for a greater number of roles and tests per month, making it suitable for growing businesses.",
          boost_up_candidate_title:
            "READY FOR BOOST UP YOUR POTENTIAL TALENT FOR YOUR COMPANY ?",
          boost_up_candidate_capt:
            " Increase efficiency, productivity and business performance with various products and services from Highr ID",
          company_heropage_title: "Online Assessment Platform",
          company_heropage_capt:
            "Highr ID is a comprehensive assessment platform in Indonesia that facilitates accurate and efficient recruitment of the best talent.",
          about_us: "About Us",
          about_us_title: "Candidate Assessment Services Company",
          about_us_capt:
            "As a dedicated corporate entity, we pride ourselves in providing services specifically focused on in-depth assessment of employee behavior and skills.",
          our_mission: "Our Mission",
          our_mission_title:
            "Help Companies Hiring The Best Candidates Efficiently",
          our_mission_capt:
            "We want to help companies hiring the best candidates efficiently.",
          milestone_title: "Companies That Have Growth With Us",
          milestone_capt:
            "Highr ID has helped companies to optimize their talent growth with developing an assessment that brings most best results with accurate report and deliverability with full cycle of recruitment. ",
          milestone1:
            "In 2022, Highr ID was established with a mission to make it easier for companies to find the best candidates.",
          milestone2:
            "In 2023, The Highr ID company achieved a milestone by successfully facilitating more than 100 companies in finding the best candidate that fits their needs and values.",
          moment_title: "Moment With Highr ID",
          total_applicant: "Total Users & Assessments",
          total_applicant_capt:
            "Highr ID has helped many applicants to conduct assessments through a digital platform that can be accessed anywhere and anytime.",
          contact_us: "Contact Us",
          contact_us_capt:
            "Send us a message! Our team will contact you and respond to your inquiries",
          manage_candidate: "Manage Candidate",
          assessment_optimally: "Assesment Optimally",
          intro_video_capt:
            "Here at Highr ID we focus on recruiting and assessing candidates with to suit the needs of each job",
          // ...other translations...
        },
      },
      id: {
        translations: {
          search: "Cari",
          benefit_title: "Rasakan keuntungan dari Highr ID.",
          benefit_capt:
            "Mengevaluasi dengan Ilmu Pengetahuan dan Menemukan yang Terbaik dengan 300+ Penilaian yang telah diambil oleh Kandidat dengan Akurasi dan Laporan Otomatisasi",
          benefit_point1:
            "Akses di setiap perangkat, dari ponsel hingga desktop yang didukung dengan monitor Anti Kecurangan dan tangkapan waktu nyata.",
          benefit_point2:
            "Jalankan tes ganda dari keterampilan teknis hingga keterampilan lunak dengan penyesuaian mudah untuk tim dan perusahaan untuk kandidat ganda hingga ratusan bakat.",
          benefit_point3:
            "Menyesuaikan penilaian berdasarkan kategori dan industri dengan tes yang dipersonalisasi untuk kandidat Anda dari akuisisi level operasional hingga manajemen",
          benefit_point4:
            "Laporan hasil penilaian kandidat secara real time dan akses tak terbatas ke database rekrutmen dengan rencana langganan yang mudah untuk perusahaan dan praktisi Sumber Daya Manusia.",
          btn_trial_account: "Akses Trial Account",
          days: "Hari",
          conventional: "Konvensional",
          conventional_capt: "REKRUTER MEMBUTUHKAN 5 HARI KERJA",
          conventional_desc:
            "Rekrutmen dengan metode lama bisa memakan waktu hingga 5 hari untuk menyortir kandidat, menjalankan uji pengguna, menyaring kandidat dan memantau database yang berbeda",
          content_landing_button: "Pilih Paket Sekarang",
          humata_method: "SORTIR, UJI DAN TERIMA DALAM 1 HARI",
          humata_method_desc:
            "Rekrut karyawan lebih mudah dengan platform penilaian yang bisa daftar kandidat, hasil kandidat, dan laporan yang akurat untuk perusahaan Anda.",
          contact_us: "Hubungi Kami",
          free_consultation: "Konsultasi Gratis",
          free_consultation_title:
            "Mulai langkah pertama dalam mengenali dan mengelola talenta berbasis data",
          welcome: "Rekrut Kandidat Terbaik",
          welcome_capt:
            "Kami adalah perusahaan yang menyediakan layanan penilaian karyawan dan keterampilan kerja. Kami membantu perusahaan Anda menemukan bakat yang tepat.",
          partner_title: "Klien Yang Telah Berlangganan",
          heropage2_title: "Nilai dan Kelola Kandidat dengan",
          heropage2_caption:
            "Di Highr ID, kami fokus pada merekrut dan menilai kandidat untuk memenuhi kebutuhan setiap pekerjaan",
          reportpage_title: "Identifikasi kandidat terbaik dengan",
          reportpage_capt1: "Pertajam Proses Rekrutmen Anda:",
          reportpage_capt2:
            "Identifikasi Kandidat Terbaik dengan Platform Canggih Kami",
          gettingstarted_title: "Mulailah dengan mudah seperti",
          gettingstarted_capt:
            "Dalam beberapa langkah mudah, Anda siap mengelola penilaian kandidat Anda.",
          journey_big_title:
            "Mulai Rekrutmen & Penilaian Kandidat Anda dengan Highr ID Sekarang",
          solution_title1: "Tingkatkan Proses Seleksi",
          solution_title2: "Kandidat Anda",
          solution_capt:
            "Penilaian kandidat dengan tingkat validasi tertinggi untuk menilai kemampuan kognitif, kompetensi, motivasi, dan kepribadian.",
          solution_feature_title1: "Dipercayai Oleh Berbagai Perusahaan",
          solution_feature_title2: "di berbagai sektor industri",
          solution_feature_capt:
            "Fitur ini dapat memudahkan Anda menilai dan merekrut kandidat di perusahaan Anda.",
          feature1_capt:
            "Klien telah menambahkan pekerjaan baru ke sistem, yang bertujuan untuk memasukkan peran pekerjaan yang akan diberikan kepada kandidat ke dalam proses penilaian.",
          feature2_capt:
            "Klien memiliki kemampuan untuk mengirim undangan kepada kandidat dengan tujuan melibatkan mereka dalam menjalankan tes yang telah dipilih sebelumnya untuk mengukur kemampuan dan kualifikasi mereka.",
          feature3_capt:
            "Struktur tes memberikan pilihan jenis pertanyaan ganda untuk mengukur keterampilan dan pengetahuan kandidat, memberikan klien kebebasan untuk memilih sesuai dengan evaluasi mereka.",
          feature4_capt:
            "Penerapan pembatasan waktu dalam penilaian bertujuan untuk mengurangi kecurangan dan meningkatkan efisiensi dan akurasi evaluasi peserta.",
          feature5_capt:
            "Unduh laporan sebagai bukti pelaksanaan tes kandidat dan hasilnya, berperan penting dalam evaluasi lebih lanjut dan pemantauan kinerja.",
          feature6_capt:
            "Klien dapat memilih untuk memberikan screening interview kepada para kandidat. Klien juga dapat memilih beberapa pertanyaan di Platform untuk dapat dijawab oleh kandidat dan bisa melihat rekaman jawaban dari pertanyaan secara real-time.",
          solution_ct_tab:
            "Menjalankan penilaian untuk kandidat yang dapat disesuaikan dengan kebutuhan perusahaan Anda dan mendukung praktik rekrutmen dengan memberikan rekomendasi personalisasi oleh tim konsultan kami untuk penilaian berdasarkan profil industri Anda dengan tes yang disaring yang sesuai dengan kualifikasi pekerjaan dari posisi level entry hingga manajemen.",
          solution_cr_tab:
            "Memantau kemajuan penilaian kandidat dengan laporan yang telah dianalisis dengan statistik ilmiah yang disusun menjadi satu sumber informasi yang berjalan pada kekuatan dan kelemahan kandidat, keterampilan dan pengetahuan yang membantu rekruter dan perusahaan mengawasi kandidat potensial dan meramalkan akuisisi bakat di masa depan pada satu database yang disederhanakan",
          solution_cs1_tab:
            "Memberikan layanan yang sesuai dengan produk kami, tim konsultan kami akan membantu Anda meningkatkan dan menjalankan proses rekrutmen Anda dengan",
          solution_cs2_tab:
            "Layanan lain yang dapat kami bantu selama proses akuisisi Anda adalah dari sumber daya hingga wawancara end-to-end dengan modul database sesuai permintaan Anda",
          solution_rate_candidate: "Beri Penilaian Dari Hasil Tes",
          solution_rate_candidate_capt:
            'Dapat menghasilkan berbagai tindakan berdasarkan proses evaluasi yang dilakukan pada setiap kandidat, yang mencakup penilaian sebagai "good fit", "may be", or "not fit". Berdasarkan tindakan yang diberikan, setiap kandidat akan menerima pesan yang sesuai dengan hasil penilaian, membantu mereka memahami sejauh mana kesesuaian mereka untuk posisi yang dimaksud.',
          solution_display_match: "Menampilkan Nilai Kesesuaian Kandidat",
          solution_display_match_capt:
            "Menunjukkan persentase kesesuaian antara profil kandidat dan komponen pekerjaan yang dibutuhkan, sambil memberikan ringkasan informasi yang komprehensif dan relevan tentang kandidat. Ini bertujuan untuk mendukung perusahaan dalam membuat keputusan.",
          content_landing_feature: "ATUR PAKET ANDA SEKARANG!",
          content_landing_title: "Mulai Rekrutmen & Nilai Kandidat Anda",
          content_landing_desc:
            "Apakah Anda siap membuat perusahaan Anda lebih terorganisir? Pilih & Atur paket Anda sekarang!",
          package_title: "Paket Highr ID",
          package_capt:
            "Anda dapat mengakses setiap bulan dan setiap penilaian, sesuai dengan kebutuhan perusahaan Anda",
          package_starter_capt:
            "Ini adalah paket dasar, sempurna untuk individu atau bisnis kecil yang baru memulai. Ini memberikan akses ke beberapa fitur keren untuk memulai proses penilaian.",
          package_pro_capt:
            "Paket ini dirancang untuk profesional yang membutuhkan fitur lebih canggih. Ini memungkinkan jumlah peran dan tes yang lebih besar setiap bulan, sehingga cocok untuk bisnis yang berkembang.",
          package_enterprise_capt:
            "Ditujukan untuk bisnis besar atau perusahaan, paket ini menawarkan lebih banyak peran dan tes setiap bulan. Ini termasuk fitur premium untuk menangani volume penilaian yang lebih besar.",
          package_enterprise_plus_capt:
            "Paket ini dirancang untuk profesional yang membutuhkan fitur lebih canggih. Ini memungkinkan jumlah peran dan tes yang lebih besar setiap bulan, sehingga cocok untuk bisnis yang berkembang.",
          boost_up_candidate_title:
            "SIAP UNTUK MENGANGKAT BAKAT POTENSI ANDA UNTUK PERUSAHAAN ANDA?",
          boost_up_candidate_capt:
            "Tingkatkan efisiensi, produktivitas, dan kinerja bisnis dengan berbagai produk dan layanan dari Highr ID",
          company_heropage_title: "Platform Penilaian Online",
          company_heropage_capt:
            "Highr ID adalah platform penilaian komprehensif di Indonesia yang memfasilitasi rekrutmen bakat terbaik dengan akurat dan efisien.",
          about_us: "Tentang Kami",
          about_us_title: "Perusahaan Layanan Penilaian Kandidat",
          about_us_capt:
            "Sebagai entitas korporat yang berdedikasi, kami bangga menyediakan layanan yang secara khusus difokuskan pada penilaian mendalam terhadap perilaku dan keterampilan karyawan.",
          our_mission: "Misi Kami",
          our_mission_title:
            "Membantu Perusahaan Merekrut Kandidat Terbaik Dengan Efisien",
          our_mission_capt:
            "Kami ingin membantu perusahaan merekrut kandidat terbaik dengan efisien.",
          milestone_title: "Perusahaan Yang Telah Tumbuh Bersama Kami",
          milestone_capt:
            "Highr ID telah membantu perusahaan mengoptimalkan pertumbuhan bakat mereka dengan mengembangkan penilaian yang memberikan hasil terbaik dengan laporan yang akurat dan pengiriman penuh siklus rekrutmen.",
          milestone1:
            "Tahun 2022, Highr ID didirikan dengan misi untuk memudahkan perusahaan menemukan kandidat terbaik.",
          milestone2:
            "Tahun 2023, Perusahaan Highr ID berhasil memfasilitasi lebih dari 100 perusahaan dalam menemukan kandidat terbaik yang sesuai dengan kebutuhan dan nilai mereka.",
          moment_title: "Moment Bersama Highr ID",
          total_applicant: "Total Pengguna & Penilaian",
          total_applicant_capt:
            "Highr ID telah banyak membantu pelamar untuk melakukan penilaian melalui platform digital yang dapat diakses dimana saja dan kapan saja.",
          contact_us: "Hubungi Kami",
          contact_us_capt:
            "Kirimkan pesan kepada kami, dan tim kami akan menghubungi Anda dan merespons pertanyaan Anda",
          manage_candidate: "Kelola Kandidat",
          assessment_optimally: "Optimalkan Penilaian",
          intro_video_capt:
            "Di Highr ID, kami fokus pada merekrut dan menilai kandidat untuk memenuhi kebutuhan setiap pekerjaan",
          // ...other translations...
        },
      },
    },
    fallbackLng: "en",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["localStorage", "cookie"],
    },
  });

export default i18n;
