const CustomizedTest = ({Feature, FeatureTitle, FeatureDesc}) =>{
    return(
            <div className="flex flex-col text-center md:text-left md:w-1/2">
                <h2 className="font-bold text-main-violate ">{Feature}</h2>
                <h1 className="font-bold text-2xl md:text-4xl">{FeatureTitle}</h1>
                <p>{FeatureDesc}</p>
            </div>
            
    );

}

export default CustomizedTest