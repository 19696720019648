import React from "react";
import illustration from "./../../Assets/anti_cheat.svg";
import illustration2 from "./../../Assets/Personality_Score_Card_2.svg";
import illustration3 from "./../../Assets/banchmark_overview.svg";
import illustration4 from "./../../Assets/Personality_Score_Card.svg";
import { useTranslation } from "react-i18next";
export default function ReportPage() {
  const { t } = useTranslation();
  return (
    <div className="container text-center mx-auto w-full mt-10 flex flex-col px-7 mt-[100px] md:mt-[70px] gap-4">
      <h1 className="font-bold text-xl md:text-4xl">
        {t('reportpage_title')} <span className="text-main-violate">reports</span>
      </h1>
      <p className="mdb:text-lg text-md pt-1.5 max-w-xl text-center mx-auto">
        {t('reportpage_capt1')} <br />
        {t('reportpage_capt2')}
      </p>
      <div className="container flex flex-col justify-center mt-8 rounded-lg ">
        <div className="container flex flex-col mdb:flex-row items-center justify-center gap-4 mx-auto px-6 w-full">
          <img
            src={illustration4}
            className="w-[255px] border border-main-violate-100 rounded-lg"
            alt="Your SVG"
          />

          <img
            src={illustration3}
            className="w-25 border border-main-violate-100 rounded-lg"
            alt="Your SVG"
          />

          <img
            src={illustration2}
            className="w-[233px] border border-main-violate-100 rounded-lg"
            alt="Your SVG"
          />
        </div>
        <div className="container flex justify-center align-center gap-4 mx-auto px-6 mt-4">
          <div>
            <img
              src={illustration}
              className="w-full border border-main-violate-100 rounded-lg"
              alt="Your SVG"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
