import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getArticles, getArticleTopics } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import PageSpinner from "../../Component/PageSpinner";
import { truncateDescription } from "../../helpers";
import { useSelector } from "react-redux";
import { HandThumbUpIcon as LikedIcon } from "@heroicons/react/24/solid";
import { HandThumbUpIcon as LikeIcon } from "@heroicons/react/24/outline";
import moment from "moment";
const ArticlePage = ({}) => {
  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const topic = queryParams.get("topic") || null;
  const tag = queryParams.get("tag") || null;

  const [nextPage, setNextPage] = useState(1);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { language } = useSelector((state) => state.general);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [topics, setTopics] = useState([]);
  const fetchArticles = async (next) => {
    setLoading(true);
    const result = await getArticles(language, topic, tag, next);
    if (result.success) {
      setArticles([...articles, ...result.data.data]);
      if (result.data.next_page_url) {
        setNextPage(nextPage + 1);
      } else {
        setNextPage(null);
      }
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(false);
  };

  const fetchArticleTopics = async () => {
    const result = await getArticleTopics();
    if (result.success) {
      setTopics(result.data);
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    const scrollPosition = scrollTop + clientHeight;
    const scrollLimit = scrollHeight * 0.7;

    if (scrollTop > lastScrollTop && scrollPosition >= scrollLimit) {
      setLastScrollTop(scrollTop);
      if (articles && nextPage) {
        if (!loading) {
          fetchArticles(nextPage);
        }
      }
    }
  };

  useEffect(() => {
    // Pastikan untuk melepas event listener ketika komponen di-unmount
    document.addEventListener("scroll", handleScroll);

    // Bersihkan event listener pada unmount
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    fetchArticleTopics();
    fetchArticles();
  }, []);
  return (
    <div>
      <ToastContainer />
      {/** PLEASE CREATE A JUMBOTRON TOSHOW ACTIVE TAG */}
      {tag && (
        <div className="bg-gray-100 w-full h-[200px] flex flex-col justify-center items-center">
          <p className="font-semibold text-[15px] text-gray-400">Tag</p>
          <h1 className="text-3xl font-bold mb-2">{tag}</h1>
          <p className="font-semibold text-[15px]">Highr ID</p>
        </div>
      )}
      {topic && (
        <div className="bg-gray-100 w-full h-[200px] flex flex-col justify-center items-center">
          <p className="font-semibold text-[15px] text-gray-400">Topic</p>
          <h1 className="text-3xl font-bold mb-2">
            {topic
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </h1>
          <p className="font-semibold text-[15px]">Highr ID</p>
        </div>
      )}

      <div className="flex justify-center gap-16 bg-white w-full py-4">
        <div className="article-content w-[680px] min-h-[720px] px-2 md:px-0">
          {articles?.map((article) => (
            <div className="list-article-box w-full px-2 py-2 border-b mt-4">
              <span className="flex space-x-1 mb-1">
                <p className="text-[13px]">{article.author}</p>
                <p className="text-[13px] text-gray-600">
                  · {moment(article.created_at).format("MMM D, YYYY")}
                </p>
              </span>
              <div className="content flex min-h-[120px] md:space-x-6 space-x-5 mb-3">
                <div
                  className="text w-[70%] md:w-[80%] cursor-pointer"
                  onClick={(e) => navigate(`/articles/${article.slug}`)}
                >
                  <h1 className="text-[16px] mdb:text-[22px] md:text-[18px] font-bold limit-2">
                    {article.title}
                  </h1>
                  <p className="text-[14px] md:text-[16px] text-gray-600 limit-2 md:limit-3 mt-2">
                    {/* {truncateDescription(article.description, 30)} */}
                    {article.description}
                  </p>
                </div>
                <div className="image w-[30%] md:w-[20%]">
                  <div className="image-container h-full max-h-[90px] md:max-h-[120px]">
                    <img
                      src={article.image}
                      className="w-full h-full object-cover"
                      alt={article.caption}
                    />
                  </div>
                </div>
              </div>
              <div className="flex text-sm items-center mb-4">
                {article.liked ? (
                  <LikedIcon className="h-5 w-5 text-gray-500 mr-1 leading-0" />
                ) : (
                  <LikeIcon className="h-5 w-5 text-gray-500 mr-1 leading-0" />
                )}
                {article.likes} Likes
              </div>
              <div className="flex overflow-x-auto flex-nowrap w-[100%] mb-4 space-x-2">
                <a
                  className="bg-indigo-500 text-white px-3 py-1 rounded-full text-sm flex-shrink-0"
                  href={`/articles?tag=${article.tags[0]}`}
                >
                  {article.tags[0]}
                </a>
                {/* {article.tags?.map((tag) => (
                    <button className="bg-indigo-500 text-white px-3 py-1 rounded-full text-sm flex-shrink-0">
                      {tag}
                    </button>
                  ))} */}
              </div>
            </div>
          ))}
          {loading && (
            <>
              <div className="py-4">
                <PageSpinner />
              </div>
            </>
          )}
        </div>
        <div className="hidden mdb:block">
          <div className="sticky top-20 py-4">
            <div className="max-w-[380px] h-[600px] flex flex-col space-y-4">
              <p className="font-semibold">
                Discover more of what matters to you
              </p>
              <div className="flex gap-2 flex-wrap">
                {topics?.map((item) => (
                  <>
                    {item.slug == topic && (
                      <a
                        className="bg-gray-500 text-white px-4 py-2 rounded-full text-sm flex-shrink-0"
                        href="#"
                      >
                        {item.name}
                      </a>
                    )}
                    {item.slug != topic && (
                      <a
                        className="bg-indigo-500 text-white px-4 py-2 rounded-full text-sm flex-shrink-0"
                        href={`/articles?topic=${item.slug}`}
                      >
                        {item.name}
                      </a>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col gap-12 md:gap-28 justify-center align-center md:mx-auto">
            A
        </div> */}
      </div>
    </div>
  );
};

export default ArticlePage;
