import { useTranslation } from "react-i18next";
const HeroPage = () => {
  const { t } = useTranslation("translations");
  return (
    <div className="container text-center mx-auto mt-[100px] mb-11 flex flex-col justify-center align-center bg-white">
      <h1 className="font-bold  text-2xl md:text-5xl">
        {t("company_heropage_title")}
      </h1>
      <p className="text-base pt-1.5 max-w-[520px] mx-auto">
        {t("company_heropage_capt")}
      </p>
    </div>
  );
};

export default HeroPage;
