import ContentLanding from "../../Component/ContentLanding";
import CustomizedTest from "./Components/CustomizedTest";
import Feature from "./Feature";
import Image1 from "./../../Assets/Solution_Image_1.svg";
import Image2 from "./../../Assets/Solution_Image_2.svg";
import Image3 from "./../../Assets/Solution_Image_3.svg";
import {
  BriefcaseIcon,
  CheckCircleIcon,
  CalendarDaysIcon,
} from "@heroicons/react/20/solid";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const SolutionPage = () => {
  const [activeTab, setActiveTab] = useState("customize");
  const { t } = useTranslation();
  return (
    <div className="flex flex-col mx-auto bg-white">
      <div className="container mx-auto w-full mt-10 flex flex-col bg-white px-5 mt-[100px] md:mt-[70px]">
        <h1 className="font-bold text-2xl md:text-5xl text-center">
          {t("solution_title1")}
          <br /> {t("solution_title2")}
        </h1>
        <p className="mt-5 text-center">
          {t("solution_capt")}
        </p>
        <div className="bg-main-violate-900 px-0 md:px-6 py-6 flex rounded-[50px] mt-[90px]">
          <div className="w-1/3 flex flex-col items-center">
            <BriefcaseIcon className="text-white w-[40px] md:w-[50px]" />
            <p className="text-white text-base md:text-xl font-bold">100</p>
            <p className="text-white text-xs md:text-lg">JOB ROLE</p>
          </div>
          <div className="w-1/3 flex flex-col items-center">
            <CheckCircleIcon className="text-white w-[40px] md:w-[50px]" />
            <p className="text-white text-base md:text-xl font-bold">+9</p>
            <p className="text-white text-xs md:text-lg">ASSESSMENT TEST</p>
          </div>
          <div className="w-1/3 flex flex-col items-center">
            <CalendarDaysIcon className="text-white w-[40px] md:w-[50px]" />
            <p className="text-white text-base md:text-xl font-bold">2</p>
            <p className="text-white text-xs md:text-lg">DAYS PROCESS</p>
          </div>
        </div>

        <Feature />
        <div>
          <div className="mt-[100px] mb-[30px]">
            <div className="flex justify-center">
              <nav className="bg-gray-100 rounded-md mdb:bg-transparent py-2 px-2 w-full">
                <div className="flex space-x-1 mdb:space-x-4 overflow-x-auto  ">
                  <a
                    href="#"
                    className={`mdb:text-base text-sm ${
                      activeTab === "customize"
                        ? "text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg flex-shrink-0"
                        : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200 flex-shrink-0"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("customize");
                    }}
                  >
                    CUSTOMIZE TEST
                  </a>
                  <a
                    href="#"
                    className={`mdb:text-base text-sm  ${
                      activeTab === "candidate-report"
                        ? "text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg flex-shrink-0"
                        : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200 flex-shrink-0"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("candidate-report");
                    }}
                  >
                    CANDIDATE REPORT
                  </a>
                  <a
                    href="#"
                    className={`mdb:text-base text-sm  ${
                      activeTab === "consultation"
                        ? "text-[#8F03ED] py-2 px-4 font-semibold bg-[#FBF3FF] rounded-lg flex-shrink-0"
                        : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200 flex-shrink-0"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("consultation");
                    }}
                  >
                    CONSULTANT SERVICES
                  </a>
                </div>
              </nav>
            </div>
          </div>
          <div className="mb-6 mdb:px-5 p-0">
            <div className="flex flex-col w-full">
              <div className="">
                {activeTab === "customize" && (
                  <div>
                    <p className="text-base mdb:text-lg mb-6 text-justify">
                      {t("solution_ct_tab")}
                    </p>

                    <div className="flex flex-wrap justify-center">
                      <div className="w-full md:1/2 lg:w-1/4  px-2 mt-4">
                        <div className="bg-main-violate-100 p-5 rounded-lg flex flex-col min-h-[200px] w-full">
                          <p className="font-semibold text-lg mb-3">
                            COGNITIVE TEST
                          </p>
                          <ul className="list-disc list-inside space-y-1">
                            <li>NUMERICAL</li>
                            <li>CRITICAL THINKING</li>
                            <li>MORE TEST TO COME</li>
                          </ul>
                        </div>
                      </div>

                      <div className="w-full md:1/2 lg:w-1/4  px-2 mt-4">
                        <div className="bg-main-violate-100 p-5 rounded-lg flex flex-col min-h-[200px] w-full">
                          <p className="font-semibold text-lg mb-3">
                            PERSONALITY
                          </p>
                          <ul className="list-disc list-inside space-y-1">
                            <li>PROFESSIONAL BEHAVIOUR</li>
                            <li>DISC</li>
                            <li>MBTI</li>
                            <li>MORE TEST TO COME</li>
                          </ul>
                        </div>
                      </div>

                      <div className="w-full md:1/2 lg:w-1/4  px-2 mt-4">
                        <div className="bg-main-violate-100 p-5 rounded-lg flex flex-col min-h-[200px] w-full">
                          <p className="font-semibold text-lg mb-3">
                            SOFT SKILL
                          </p>
                          <ul className="list-disc list-inside space-y-1">
                            <li>COMMUNICATION</li>
                            <li>NEGOTIATION</li>
                            <li>MORE TEST TO COME</li>
                          </ul>
                        </div>
                      </div>

                      <div className="w-full md:1/2 lg:w-1/4  px-2 mt-4">
                        <div className="bg-main-violate-100 p-5 rounded-lg flex flex-col min-h-[200px] w-full">
                          <p className="font-semibold text-lg mb-3">
                            HARD SKILL
                          </p>
                          <ul className="list-disc list-inside space-y-1">
                            <li>MICROSOFT OFFICE OPERATION</li>
                            <li>TECHNICAL TEST</li>
                            <li>MORE TEST TO COME</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "candidate-report" && (
                  <>
                    <div>
                      <p className="text-base mdb:text-lg mb-6 text-justify">
                        {t("solution_cr_tab")}
                      </p>
                    </div>
                  </>
                )}
                {activeTab === "consultation" && (
                  <>
                    <div>
                      <p className="text-base mdb:text-lg mb-6 text-justify">
                       {t("solution_cs1_tab")}
                      </p>
                      <p className="text-base mdb:text-lg mb-6 text-justify">
                        {t("solution_cs2_tab")}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-8 md:gap-[120px] mt-14 md:mt-[152px] mb-20 md:mb-[120px]">
          {/* <div className="flex flex-col gap-6 md:flex-row-reverse items-center">
            <CustomizedTest
              Feature={"CUSTOMIZE TEST"}
              FeatureTitle={"Customize Assessment For The Job Position"}
              FeatureDesc="Make an evaluation of the profile that has been upgraded and adjusted to the competencies and characteristics of the best employees in your company. From simple assessments 
        for entry-level positions to assessments for more intricate job titles and profiles, everything can be adapted to 
        suit your needs"
            />
            <img src={Image1} className=" md:w-1/2" alt="Showcase" />
          </div> */}
          <div className="flex flex-col gap-6 md:flex-row items-center">
            <CustomizedTest
              className="flex-row-reverse"
              Feature={"RATE CANDIDATE"}
              FeatureTitle={t('solution_rate_candidate')}
              FeatureDesc={t('solution_rate_candidate_capt')}
            />
            <img src={Image2} className=" md:w-1/2" alt="Showcase" />
          </div>
          <div className="flex flex-col gap-6 md:flex-row-reverse items-center">
            <CustomizedTest
              Feature={"COMPATIBILITY INDEX"}
              FeatureTitle={t('solution_display_match')}
              FeatureDesc={t('solution_display_match_capt')}
            />
            <img src={Image3} className=" md:w-1/2" alt="Showcase" />
          </div>
        </div>
      </div>
      <div className="px-2 mx-auto">
        <ContentLanding />
      </div>
    </div>
  );
};

export default SolutionPage;
