import illustration from "./../../../Assets/Card_Company_Profile.svg";
import illustration2 from "./../../../Assets/Card_Test.svg";
import illustration3 from "./../../../Assets/Component_1.svg";
import illustration4 from "./../../../Assets/Personality_Score_Card.svg";
import AdminDashboard from "./../../../Assets/admin-dashboard.webp";
import React from "react";
export default function HeroImage() {
  return (
    <>
      <div className="container flex justify-center align-center mt-8 rounded-lg box-shadow-1">
        <img src={AdminDashboard} className="w-full overflow-hidden rounded-lg" alt="Admin Dashboard" />
      </div>
      {/* <div className="container flex justify-center align-center gap-4 mx-auto my-8 md:my-24 px-6">
        <div>
          <img src={illustration4} className="w-[255px]" alt="Your SVG" />
        </div>
        <div className="flex flex-col justify center align-center gap-4 rounded-lg">
          <img src={illustration} className="w-25" alt="Your SVG" />
          <img src={illustration3} className="w-25" alt="Your SVG" />
        </div>
        <div>
          <img src={illustration2} className="w-[233px]" alt="Your SVG" />
        </div>
      </div> */}
    </>
  );
}
