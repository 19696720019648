import { useEffect, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Component/FooterLanding";
import NavbarLanding from "../Component/NavbarLanding";
import config from "../config";
const LandingLayout = () => {
  const [user, setUser] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div className="relative">
        <div className="w-full fixed z-50">
          <NavbarLanding />
        </div>
        <div className="z-0">
          <div className="pt-[60px]">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LandingLayout;
